import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, Button, Form } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './TodoApp.css'; // Create this CSS file for custom styles

const TodoApp = () => {
    const [todos, setTodos] = useState([]);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    useEffect(() => {
        fetchTodos();
    }, []);

    const fetchTodos = async () => {
        try {
            const response = await axios.get('https://mytodobackend.creativevisions.live/api/todos');
            setTodos(response.data);
        } catch (error) {
            console.error("Error fetching todos:", error);
        }
    };

    const addTodo = async (e) => {
        e.preventDefault();
        if (!title || !description) {
            alert('Title and description are required.');
            return;
        }

        try {
            await axios.post('https://mytodobackend.creativevisions.live/api/todos', { title, description });
            setTitle('');
            setDescription('');
            fetchTodos();
        } catch (error) {
            console.error("Error adding task:", error);
        }
    };

    const updateTodoStatus = async (id, status) => {
        try {
            await axios.put(`https://mytodobackend.creativevisions.live/api/todos/${id}`, { status });
            fetchTodos();
        } catch (error) {
            console.error("Error updating task status:", error);
        }
    };

    const deleteTodo = async (id) => {
        try {
            await axios.delete(`https://mytodobackend.creativevisions.live/api/todos/${id}`);
            fetchTodos();
        } catch (error) {
            console.error("Error deleting task:", error);
        }
    };

    return (
        <div className="container mt-5">
            <h2 className="text-center mb-4">To-Do List</h2>
            <div className="card p-4 todo-card">
                <Form onSubmit={addTodo}>
                    <Form.Group>
                        <Form.Control
                            type="text"
                            placeholder="Enter task title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            required
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Control
                            as="textarea"
                            rows={2}
                            placeholder="Enter task description"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                        />
                    </Form.Group>
                    <Button type="submit" variant="primary" block>Add Task</Button>
                </Form>
            </div>
            <Table striped bordered hover className="mt-4 todo-table">
                <thead>
                    <tr>
                        <th>Title</th>
                        <th>Description</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {todos.map(todo => (
                        <tr key={todo.id} className={todo.status}>
                            <td>{todo.title}</td>
                            <td>{todo.description}</td>
                            <td>
                                <span className={`status-badge ${todo.status}`}>
                                    {todo.status.charAt(0).toUpperCase() + todo.status.slice(1)}
                                </span>
                            </td>
                            <td>
                                {todo.status === 'pending' ? (
                                    <>
                                        <Button
                                            variant="success"
                                            size="sm"
                                            onClick={() => updateTodoStatus(todo.id, 'completed')}
                                        >
                                            Complete
                                        </Button>
                                        <Button
                                            variant="warning"
                                            size="sm"
                                            className="ml-2"
                                            onClick={() => updateTodoStatus(todo.id, 'pending')}
                                        >
                                            Mark Pending
                                        </Button>
                                    </>
                                ) : (
                                    <Button
                                        variant="warning"
                                        size="sm"
                                        onClick={() => updateTodoStatus(todo.id, 'pending')}
                                    >
                                        Mark Pending
                                    </Button>
                                )}
                                <Button
                                    variant="danger"
                                    size="sm"
                                    className="ml-2"
                                    onClick={() => deleteTodo(todo.id)}
                                >
                                    Delete
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};

export default TodoApp;
